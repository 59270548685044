import React from 'react';
import { RecipeStyles, ProfilePic, AddedBy } from '../styles.js';
import { Link } from 'react-router-dom';

const Recipe = props => {
  const recipe = props.recipe;

  return (
    <RecipeStyles>
      <h2>{recipe.name}</h2>
      <p>{recipe.description}</p>
      <AddedBy>
        <p><Link to={`/recipes/${recipe.id}`}>Go to recipe »</Link></p>
        <ProfilePic src={recipe.profile_photo_url} alt={`${recipe.username}`} title={`Added by ${recipe.username}`} />
      </AddedBy>
    </RecipeStyles>
  )
}

export default Recipe;
