import styled from 'styled-components';

export const RecipeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`
export const RecipeStyles = styled.div`
  width: 60%;
  margin: 10px auto;
  border-radius: 7px;
  padding: 10px 40px;
  margin: 20px;
  background-color: #3993DD;
  color: #EDF5FB;
`
export const ProfilePic = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
`
export const NavBar = styled.div`
  display: flex;
  background-color: #29E7CD;
  color: #082A26;
  justify-content: space-between;
  a {
    color: inherit;
    text-decoration: none;
    padding: 25px 20px;
  }
`
export const NavLinks = styled.div`
  display: flex;
  justify-content: space-around;
  a {
    :hover {
      background-color: #1A4365;
    }
  }
`
export const AddedBy = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  a {
    color: #562433;
    text-decoration: none;
  }
`
