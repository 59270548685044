import React from 'react';
import './App.css';
import { Route } from 'react-router-dom';

import Header from './components/Header.js';
import Home from './components/Home.js';
import RecipeDetails from './components/RecipeDetails.js';

function App() {
  return (
    <div className='App'>
      <Header />
      <Route exact path='/' component={Home} />
      <Route path='/recipes/:id' render={(props) => {
        return <RecipeDetails {...props} />
      }} />
    </div>
  );
}

export default App;
