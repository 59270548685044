import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RecipeDetails = props => {
  const [recipe, setRecipe] = useState();
  const { id } = props.match.params;

  useEffect(() => {
    axios.get(`https://neilson-fam-api.herokuapp.com/api/recipes/${id}`).then(response => {
      console.log(response);
      setRecipe(response.data.recipe[0]);
    })
  }, [id])

  if (!recipe) {
    return <h3>Loading recipe...</h3>
  }

  return (
    <div className="recipe">
      <h3>{recipe.name}</h3>
      <p>{recipe.description}</p>
      <p>Ingredients:</p>
      <ul>
        {recipe.ingredients.map((ingredient, index) => {
          return <li key={index}>{ingredient}</li>
        })}
      </ul>
      <p>Directions:</p>
      <ol>
        {recipe.directions.map((step, index) => {
          return <li key={index}>{step}</li>
        })}
      </ol>
    </div>
  )
}

export default RecipeDetails;
