import React from 'react';
import Recipe from './Recipe.js';
import { RecipeContainer } from '../styles.js';

const RecipeList = props => {
  return (
    <RecipeContainer>
      {props.recipes.map((recipe, index) => {
        return (
          <Recipe recipe={recipe} key={index} />
        )
      })}
    </RecipeContainer>
  )
}

export default RecipeList;
