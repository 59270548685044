import React from 'react';
import { Link } from 'react-router-dom';
import { NavBar, NavLinks } from '../styles.js';

const Header = () => {
  return (
    <NavBar>
      <Link to='/'>Neilson Cookbook</Link>
      <NavLinks>
        <Link to='/login'>Log In</Link>
        <Link to='/signup'>Sign Up</Link>
      </NavLinks>
    </NavBar>
  )
}

export default Header;
