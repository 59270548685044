import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RecipeList from './RecipeList.js';

const Home = () => {
  const [recipes, setRecipes] = useState();

  useEffect(() => {
    axios.get('https://neilson-fam-api.herokuapp.com/api/recipes').then(response => {
      console.log(response);
      setRecipes(response.data.recipes);
    }).catch(err => {
      console.log(err);
    })
  }, [])

  if (!recipes) {
    return(
      <h3>Loading recipes...</h3>
    )
  }

  return (
    <RecipeList recipes={recipes} />
  )
}

export default Home;
